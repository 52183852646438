import axios, { AxiosRequestConfig } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import * as url from 'url';

import { PATH_TO_STATISTICS_GATEWAY } from '@shared/constants';
import { getDateOnlyString } from '@shared/util/dateUtils';

export enum DateRangeRecipient {
  Report,
  TrendCurvesViewer,
}

/**
 * Represents a range of dates in a local time zone.
 * @export
 * @interface DateRange
 */
export interface DateRange {
  /**
   * Gets the period start date. Inclusive.
   * @type {Date}
   * @memberof DateRange
   */
  startDate: string;
  /**
   * Gets the period end date. Inclusive.
   * @type {Date}
   * @memberof DateRange
   */
  endDate: string;

  /**
   * Get length of date range.
   * @type {number}
   * @memberof DateRange
   */
  periodLengthInDays: number;
}

export interface ReportDates extends DateRange {
  trendCurveDates: DateRange[];
}

export enum ReportType {
  /**
   * Report with all sections.
   **/
  Detailed = 0,

  /**
   * Report with only compliance section
   **/
  ComplianceOnly = 1,
}

export const ReportsApi = {
  /**
   * Generates compliance report for the all patients.
   * @param startDate Report start date.
   * @param endDate Report end date.
   * @param options Overrides HTTP request options.
   */
  async getComplianceSummaryCsv(
    startDate: Dayjs,
    endDate: Dayjs,
    options?: AxiosRequestConfig,
  ): Promise<{ content: ArrayBuffer; filename: string }> {
    const request = {
      startDate: getDateOnlyString(startDate),
      endDate: getDateOnlyString(endDate),
    };
    options = {
      ...options,
      responseType: 'arraybuffer',
    };
    const params = url.format({ query: request });
    const response = await axios.get(`${PATH_TO_STATISTICS_GATEWAY}/api/csv/compliance-summary${params}`, options);

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const filename = response.headers['content-disposition'].match(filenameRegex)?.[1] ?? '';

    return {
      content: response.data,
      filename: filename,
    };
  },

  /**
   * Generates a report for the specified patient.
   * @param patientPrismaCloudId The id of the patient.
   * @param startDate Report start date.
   * @param endDate Report end date.
   * @param uiLocale The locale of report.
   * @param locale The locale of formatting of dates and numbers.
   * @param reportType Type of the report.
   * @param options Overrides HTTP request options.
   */
  async generateAndGetReport(
    patientPrismaCloudId: string,
    startDate: Dayjs,
    endDate: Dayjs,
    uiLocale: string,
    locale: string,
    reportType: ReportType,
    options?: AxiosRequestConfig,
  ): Promise<ArrayBuffer> {
    const request = {
      uiLocale,
      locale,
      patientPrismaCloudId,
      startDate: getDateOnlyString(startDate),
      endDate: getDateOnlyString(endDate),
      userReportCreationTime: dayjs().local().format(),
      reportType,
    };
    options = {
      ...options,
      responseType: 'arraybuffer',
    };
    const params = url.format({ query: request });
    const response = await axios.get(`${PATH_TO_STATISTICS_GATEWAY}/api/report/ui/generate${params}`, options);

    return response.data;
  },

  /**
   * Gets the valid date range for report.
   * @param patientPrismaCloudId The id of the patient.
   * @param options Overrides HTTP request options.
   */
  async getValidDatesForReport(
    patientPrismaCloudId: string,
    options?: AxiosRequestConfig & { params: { recipient: DateRangeRecipient } },
  ): Promise<ReportDates | null> {
    const response = await axios.get<ReportDates | null>(
      `${PATH_TO_STATISTICS_GATEWAY}/api/report/dates/${patientPrismaCloudId}`,
      options,
    );
    return response.data;
  },
};
