import { match } from 'ts-pattern';

import { Components, Theme } from '@mui/material';

type MuiIconButtonTheme = Components<Theme>['MuiIconButton'];
export const iconButtonTheme: MuiIconButtonTheme = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      height: match(ownerState.size)
        .with('small', () => '24px')
        .with('medium', () => '32px')
        .with('large', () => '40px')
        .otherwise(() => '32px'),
      width: match(ownerState.size)
        .with('small', () => '24px')
        .with('medium', () => '32px')
        .with('large', () => '40px')
        .otherwise(() => '32px'),
      '&.MuiButtonBase-root:hover': {
        backgroundColor: 'transparent',
      },
      padding: 0,
    }),
  },
};
